<template>
  <v-dialog
    max-width="900px"
    light
    v-model="areTermsVisible"
    close-delay="5"
    open-delay="5"
    scrollable

  >
    <template v-slot:activator="{ on, attrs }">
      <!-- open modal button -->
      <button role="button" class="button-footer" v-bind="attrs" v-on="on">
        <p
          id="verification-portal-footer-certification-practise-statement"
          class="mb-0 text-xl-body-2 text-lg-body-2 text-md-body-2 text-sm-body-1 primaryGreen font-weight-medium"
        >
          {{ $t("certificationPractiseStatement") }}
        </p>
      </button>
      <!--  -->
    </template>

    <!-- content modal -->
    <v-card v-if="areTermsVisible" class="older-versions ">
      <v-card-title
        class="d-flex justify-space-between title-terms--contaier align-center"
      >
        <p id="verification-portal-footer-terms_and_conditions_title" class="primaryGreen subtitle-2 text-uppercase mb-0 pb-1">
          {{ $t("certificationPractiseStatement") }}
        </p>

        <img
          id="verification-portal-footer-terms_and_conditions_close"
          src="../../../../../assets/icons/close.svg"
          :alt="$t('altAriaAttributes.closeIcon')"
          class="close-icon pointer"
          @click="areTermsVisible = false"
        />
      </v-card-title>
      <!-- text modal -->
      <v-card-text class="px-4 pb-0 pt-8 ">
        <v-row dense justify="center" class="mt-4">
          <h1>{{$t('activeVersion')}}</h1>
        </v-row>
        <v-row dense class="ma-0">
          <v-col cols="12" md="6" class="my-4  mb-2 px-2">
            <v-btn
              id="verification-portal-validate_btn"
              role="button"
              block
              color="primary"
              tabindex="3"
              aria-pressed="true"
              depressed
              class="button-footer"
              @click="openLink('mailteck')"
            >
              Mailteck
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="my-4 mb-2 px-2">
            <v-btn
              id="verification-portal-validate_btn"
              role="button"
              block
              color="primary"
              tabindex="3"
              aria-pressed="true"
              depressed
              class="button-footer"
              @click="openLink('customercomms')"
            >
              Customer Comms
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense justify="center" class="mt-4">
          <h1>{{$t('olderVersions')}}</h1>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-list>
              <v-list-item
                v-for="(version, index) in olderVersions.mailteck"
                :key="index"
                :title="version.url"
                class="justify-center"
                >
                <a :href="version.url" target="_blank">
                  {{ version.name }}
                </a>
              </v-list-item>

            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list>
              <v-list-item
                v-for="(version, index) in olderVersions.customercomms"
                :key="index"
                :title="version.url"
                class="justify-center"
                >
                <a :href="version.url" target="_blank">
                  {{ version.name }}
                </a>
              </v-list-item>

            </v-list>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 older-versions d-none">
          <v-col cols="12" md="6" class="mb-4 text-center">
            <a
              :title="$t('show') + ' ' + $t('olderVersions')"
              href="https://comunicaciones-legales.mailteck.com/pdf/Mailteck-Old.zip"
              >MailTecK {{ $t("olderVersions") }}</a
            >
          </v-col>
          <v-col cols="12" md="6" class="mb-4 text-center">
            <a
              :title="$t('show') + ' ' + $t('olderVersions')"
              href="https://comunicaciones-legales.customercomms.com/pdf/Ccomms-Old.zip"
              >Customer Comms {{ $t("olderVersions") }}</a
            >
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <!--  -->
  </v-dialog>
</template>
<script>
export default {
  name: "certificationPractiseStatement",
  data: function() {
    return {
      areTermsVisible: false,
      olderVersions:{
        mailteck:[
          {
            name: "MailTecK V 1.8 (03/2024)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-8.pdf"
          },
          {
            name: "MailTecK V 1.7 (12/2023)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-7.pdf"
          },
          {
            name: "MailTecK V 1.6 (01/2022)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-6.pdf"
          },
          {
            name: "MailTecK V 1.5 (07/2021)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-5.pdf"
          },
          {
            name: "MailTecK V 1.4 (02/2021)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-4.pdf"
          },
          {
            name: "MailTecK V 1.3 (02/2021)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-3.pdf"
          },
          {
            name: "MailTecK V 1.2 (02/2021)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-2.pdf"
          },
          {
            name: "MailTecK V 1.1 (02/2021)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-1.pdf"
          },
          {
            name: "MailTecK V 1.0 (12/2020)",
            url: "https://comunicaciones-legales.mailteck.com/pdf/versiones-anteriores/DPC_MailTecK-V1-0.pdf"
          }
        ],
        customercomms:[
          {
            name: "Customer Comms V 1.7 (03/2024)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-7.pdf"
          },
          {
            name: "Customer Comms V 1.6 (12/2023)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-6.pdf"
          },
          {
            name: "Customer Comms V 1.5 (01/2022)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-5.pdf"
          },
          {
            name: "Customer Comms V 1.4 (02/2021)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-4.pdf"
          },
          {
            name: "Customer Comms V 1.3 (02/2021)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-3.pdf"
          },
          {
            name: "Customer Comms V 1.2 (02/2021)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-2.pdf"
          },
          {
            name: "Customer Comms V 1.1 (02/2021)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-1.pdf"
          },
          {
            name: "Customer Comms V 1.0 (12/2020)",
            url: "https://comunicaciones-legales.customercomms.com/pdf/versiones-anteriores/DPC_CComms-V1-0.pdf"
          },

        ]
      }
    };
  },
  methods:{
    openLink(subdomain){

      let lang = this.$i18n.locale;
      let url = "";

      if (subdomain === "mailteck") {
        if (lang === "es") {
          url =
            "https://comunicaciones-legales.mailteck.com/pdf/Declaracion-practicas-certificacion-servicio-entrega-electronica-certificada_MailTecK.pdf";
        } else {
          url =
            "https://comunicaciones-legales.mailteck.com/pdf/Certified-electronic-delivery-service-certification-practice-statement_MailTecK_EN.pdf";
        }
      } else {
        if (lang === "es") {
          url =
            "https://comunicaciones-legales.customercomms.com/pdf/Declaracion-practicas-certificacion-servicio-entrega-electronica-certificada_CComms.pdf";
        } else {
          url =
            "https://comunicaciones-legales.customercomms.com/pdf/Certified-electronic-delivery-service-certification-practice-statement_CComms_EN.pdf";
        }
      }
      window.open(url, "_blank");
      this.areTermsVisible = false;
    }
  }
};
</script>
<style>
.older-versions{
  a {
    font-weight: bolder;
    text-decoration: none;
  }
  a:hover{
    color: #415e69 !important;
  }
  h1 {
    font-weight: normal;
    text-transform: uppercase;
  }
}
</style>